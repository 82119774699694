import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy, Provider } from '@kindo/universal';
import { DlpFiltersConfig } from '../DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '../DlpFiltersConfig/DlpFilters.types';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface OrgProvidersDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  provider: Provider;
}
const OrgProvidersDlpFiltersConfig: React.FC<OrgProvidersDlpFiltersProps> = ({
  canAccessSecuritySettings,
  provider
}) => {
  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listOrgProviderCustomDlpFilters.useQuery({
    provider
  });
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getOrgProviderDlpFiltersConfig.useQuery({
    provider
  }, {
    select: transformDlpFiltersConfigResponse
  });

  // Mutations
  const createOrgProviderCustomDlpFilter = nextTrpc.customDlpFilters.createOrgProviderCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully created custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const deleteOrgProviderCustomDlpFilterMutation = nextTrpc.customDlpFilters.deleteOrgProviderCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully deleted custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const updateOrgProviderDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.updateOrgProviderDlpFiltersConfig.useMutation({
    onSuccess: () => refetchDlpFiltersConfig(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update data loss prevention filter.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => updateOrgProviderDlpFiltersConfigMutation.mutate({
    provider,
    filter: dlpFilter,
    policy: newPolicy
  });
  const createOrgCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createOrgProviderCustomDlpFilter.mutate({
      provider,
      policy,
      type,
      value
    });
  };
  const deleteCustomDlpFilter = (value: string) => {
    deleteOrgProviderCustomDlpFilterMutation.mutate({
      provider,
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={createOrgCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={deleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} />;
};
export default OrgProvidersDlpFiltersConfig;