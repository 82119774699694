import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy, Llm } from '@kindo/universal';
import { DlpFiltersConfig } from '../DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '../DlpFiltersConfig/DlpFilters.types';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface OrgModelsDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  model: Llm;
}
const OrgModelsDlpFiltersConfig: React.FC<OrgModelsDlpFiltersProps> = ({
  canAccessSecuritySettings,
  model
}) => {
  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listOrgModelCustomDlpFilters.useQuery({
    model
  });
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getOrgModelDlpFiltersConfig.useQuery({
    model
  }, {
    select: transformDlpFiltersConfigResponse
  });

  // Mutations
  const createOrgModelCustomDlpFilter = nextTrpc.customDlpFilters.createOrgModelCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully created custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const deleteOrgModelCustomDlpFilterMutation = nextTrpc.customDlpFilters.deleteOrgModelCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully deleted custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const updateOrgModelDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.updateOrgModelDlpFiltersConfig.useMutation({
    onSuccess: () => refetchDlpFiltersConfig(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update data loss prevention filter.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => updateOrgModelDlpFiltersConfigMutation.mutate({
    model,
    filter: dlpFilter,
    policy: newPolicy
  });
  const createOrgCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createOrgModelCustomDlpFilter.mutate({
      model,
      policy,
      type,
      value
    });
  };
  const deleteCustomDlpFilter = (value: string) => {
    deleteOrgModelCustomDlpFilterMutation.mutate({
      model,
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={createOrgCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={deleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} />;
};
export default OrgModelsDlpFiltersConfig;