import _styled from "styled-components";
import { Popper } from '@mui/base/Popper';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
const DropdownButtonLabel = _styled.button.withConfig({
  displayName: "Dropdown__DropdownButtonLabel",
  componentId: "sc-1dd1r7-0"
})(({
  disabled
}) => [{
  "position": "relative",
  "display": "inline-block",
  "cursor": "pointer",
  "borderRadius": "9999px",
  "padding": "0.5rem",
  "textAlign": "left",
  "&[aria-expanded=\"true\"]": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
  }
}, disabled ? {
  "cursor": "not-allowed",
  "opacity": "0.5"
} : {
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
  }
}]);
const DropdownPopupContainer = _styled.div.withConfig({
  displayName: "Dropdown__DropdownPopupContainer",
  componentId: "sc-1dd1r7-1"
})({
  "position": "relative",
  "display": "flex",
  "maxHeight": "200px",
  "minWidth": "180px",
  "flexDirection": "column",
  "overflowY": "auto",
  "borderRadius": "0.375rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "padding": "0.5rem",
  "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
});
interface DropdownProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  className?: string;
  'data-id'?: string | undefined;
  disabled?: boolean;
}

// TODO: Move this component to core
export const Dropdown: React.FC<DropdownProps> = ({
  trigger,
  children,
  className,
  'data-id': dataId,
  disabled = false
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (containerRef.current && popperRef.current && !containerRef.current.contains(event.target as Node) && !popperRef.current.contains(event.target as Node)) {
      setAnchorEl(null);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  const open = Boolean(anchorEl);
  const disableTriggerButton = disabled || !children || isArray(children) && !children.length;
  return <div ref={containerRef}>
      <DropdownButtonLabel aria-expanded={open} className={className} data-id={dataId} disabled={disableTriggerButton} onClick={handleClick}>
        {trigger}
      </DropdownButtonLabel>
      <Popper ref={popperRef} anchorEl={anchorEl} open={open} placement="bottom-start" slotProps={{
      root: {
        className: 'z-50 font-sans-3'
      }
    }}>
        <DropdownPopupContainer aria-labelledby="menu-button" aria-orientation="vertical" role="menu">
          {children}
        </DropdownPopupContainer>
      </Popper>
    </div>;
};
export default Dropdown;