import { DLP_FILTER_DISPLAY_NAMES, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { Table, Toggle } from '~/components/core';
interface StandardDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  dlpFiltersConfig: Record<DlpFilter, DlpFilterPolicy> | undefined;
  dlpFiltersConfigIsLoading: boolean;
  updateStandardDlpFilterConfig: (dlpFilter: DlpFilter, newPolicy: DlpFilterPolicy) => void;
}
const StandardDlpFilters: React.FC<StandardDlpFiltersProps> = ({
  dlpFiltersConfig,
  updateStandardDlpFilterConfig,
  dlpFiltersConfigIsLoading,
  canAccessSecuritySettings
}) => <Table columns={[{
  title: 'Name'
}, {
  title: 'Redact',
  width: {
    "width": "16.666667%"
  }
}]} loading={dlpFiltersConfigIsLoading} noRowsText="No data loss prevention controls are available at this time." rows={dlpFiltersConfig ? Object.entries(dlpFiltersConfig).map(([optionName, policy]) => ({
  key: optionName,
  cells: [DLP_FILTER_DISPLAY_NAMES[optionName as DlpFilter], <Toggle checked={policy === DlpFilterPolicy.REDACT} disabled={!canAccessSecuritySettings} onToggle={(isChecked: boolean) => updateStandardDlpFilterConfig(optionName as DlpFilter, isChecked ? DlpFilterPolicy.REDACT : DlpFilterPolicy.PASSTHROUGH)} />]
})) : []} />;
export default StandardDlpFilters;