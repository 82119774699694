import _styled from "styled-components";
import { OrgPaymentTier, ORG_PAYMENT_TIER_DISPLAY_NAMES, MANAGE_SUBSCRIPTION_FULL_ROUTE } from '@kindo/universal';
import { signOut, useSession } from 'next-auth/react';
import { Button, ButtonType, Color, Icon, Icons, Size, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
import { LOGIN_ROUTE } from '~/constants';
import { useAppSelector } from '~/hooks';
import useGetCredits from '~/hooks/useGetOrgCredits';
const ProfileIconContainer = _styled.div.withConfig({
  displayName: "ProfileButton__ProfileIconContainer",
  componentId: "sc-1m7yfak-0"
})({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center"
});
const ProfileDropdownContainer = _styled.div.withConfig({
  displayName: "ProfileButton__ProfileDropdownContainer",
  componentId: "sc-1m7yfak-1"
})({
  "display": "flex",
  "width": "max-content",
  "flexDirection": "column",
  "gap": "0.25rem"
});
// Note: Padding aligns these with the padding from the sign out button
const UserInfoAndAccountInfoContainer = _styled.div.withConfig({
  displayName: "ProfileButton__UserInfoAndAccountInfoContainer",
  componentId: "sc-1m7yfak-2"
})({
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem"
});
const UserInfoContainer = _styled.div.withConfig({
  displayName: "ProfileButton__UserInfoContainer",
  componentId: "sc-1m7yfak-3"
})({
  "display": "flex",
  "flexDirection": "column",
  "borderBottomWidth": "0.063rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const TierAndUpgradeContainer = _styled.div.withConfig({
  displayName: "ProfileButton__TierAndUpgradeContainer",
  componentId: "sc-1m7yfak-4"
})({
  "display": "flex",
  "gap": "0.5rem"
});
const TierContainer = _styled.div.withConfig({
  displayName: "ProfileButton__TierContainer",
  componentId: "sc-1m7yfak-5"
})({
  "display": "flex",
  "gap": "0.25rem"
});
const AccountInfoDetailsContainer = _styled.div.withConfig({
  displayName: "ProfileButton__AccountInfoDetailsContainer",
  componentId: "sc-1m7yfak-6"
})({
  "display": "flex",
  "gap": "0.25rem"
});
const AccountInfoContainer = _styled.div.withConfig({
  displayName: "ProfileButton__AccountInfoContainer",
  componentId: "sc-1m7yfak-7"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "borderBottomWidth": "0.063rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const ProfileButton: React.FC = () => {
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const userName = user?.name;
  const userEmail = user?.email;
  const {
    orgCredits
  } = useGetCredits();

  // Redux
  const {
    org
  } = useAppSelector(state => state.user);
  const activeTier = org?.paymentTier || OrgPaymentTier.FREE;
  const isEnterprise = activeTier === OrgPaymentTier.ENTERPRISE;
  return <ProfileIconContainer>
      <Dropdown trigger={<Icons color={Color.NAVY} icon={Icon.PROFILE} size={Size.LARGE} />}>
        <ProfileDropdownContainer>
          {/* User Name + Email */}
          <UserInfoAndAccountInfoContainer>
            <UserInfoContainer>
              <Typography color={Color.NAVY} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
                {userName || userEmail}
              </Typography>
              {userName && <Typography color={Color.GRAY} size={TypographySize.SMALL} weight={TypographyWeight.LIGHT}>
                  {userEmail}
                </Typography>}
            </UserInfoContainer>
            {/* Payment Tier + Credits Remaining */}
            <AccountInfoContainer>
              <TierAndUpgradeContainer>
                <TierContainer>
                  <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                    Current plan:
                  </Typography>
                  <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                    {ORG_PAYMENT_TIER_DISPLAY_NAMES[activeTier]}
                  </Typography>
                </TierContainer>
                {!isEnterprise && <Button href={MANAGE_SUBSCRIPTION_FULL_ROUTE} label="Upgrade" size={Size.X_SMALL} textColor={Color.NAVY} type={ButtonType.OUTLINED} />}
              </TierAndUpgradeContainer>
              {orgCredits !== undefined && <AccountInfoDetailsContainer>
                  <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                    Credits Remaining:
                  </Typography>
                  <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                    {/* Show commas */}
                    {orgCredits.toLocaleString()}
                  </Typography>
                </AccountInfoDetailsContainer>}
            </AccountInfoContainer>
          </UserInfoAndAccountInfoContainer>
          {/* Sign Out */}
          <Button label="Sign Out" onClick={() => signOut({
          callbackUrl: LOGIN_ROUTE
        })} startIcon={Icon.LOGOUT} type={ButtonType.TEXT} />
        </ProfileDropdownContainer>
      </Dropdown>
      <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
        Profile
      </Typography>
    </ProfileIconContainer>;
};
export default ProfileButton;