import _styled2 from "styled-components";
import _styled from "styled-components";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

import { Background } from '../Background';
import { Nav } from '../Nav';
import ActiveModal from './ActiveModal';
import useAcceptOrgInvite from '~/hooks/useAcceptOrgInvite';
import useCheckClientVersionOutdated from '~/hooks/useCheckClientVersionOutdated';
interface LayoutProps {
  children: React.ReactElement;
}
const Layout = ({
  children
}: LayoutProps) => {
  // Hooks
  useCheckClientVersionOutdated();
  // Hook that monitors the URL for an org invite and accepts it
  useAcceptOrgInvite();
  return <_StyledDiv>
      <Background />
      <Nav />
      <ToastContainer />
      <_StyledDiv2>
        {children}
        <ActiveModal />
      </_StyledDiv2>
    </_StyledDiv>;
};
export default Layout;
var _StyledDiv = _styled("div").withConfig({
  displayName: "Layout___StyledDiv",
  componentId: "sc-mmpk64-0"
})({
  "display": "flex",
  "height": "100vh",
  "width": "100vw"
});
var _StyledDiv2 = _styled("div").withConfig({
  displayName: "Layout___StyledDiv2",
  componentId: "sc-mmpk64-1"
})({
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "overflowY": "auto"
});